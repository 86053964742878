import React from 'react';
import Button from 'components/Button';
import Container from 'components/Container';
import DjediContent from 'components/DjediContent';
import Expandable from 'components/Expandable';
import Typography from 'components/Typography';
import NODES from 'djedi-nodes/home';

import { FaqEntry } from '../../../../types/Lesson';
import Block from '../../../components/Block';

const Faq: React.FC<{ questions: FaqEntry[] }> = ({ questions = [] }) => {
  return (
    <>
      <Block>
        <Container>
          <Typography variant="heading" component="h2">
            {NODES.FAQ_TITLE}
          </Typography>
          {questions.map(({ question, answer }, i) => (
            <Expandable key={i} title={question}>
              {answer}
            </Expandable>
          ))}
        </Container>
      </Block>
      <Block>
        <Container>
          <Typography align="center" variant="heading" component="h3">
            {NODES.CONTACT_TITLE}
          </Typography>

          <DjediContent
            node={NODES.CONTACT_CTA_EMAIL_ADRESS}
            render={s => (
              <Button href={`mailto:${s.content.props.children}`} block variant="outlined">
                {NODES.CONTACT_CTA}
              </Button>
            )}
          />
        </Container>
      </Block>
    </>
  );
};

export default Faq;
