import getConfig from 'next/config';

const { publicRuntimeConfig } = getConfig();

function trackEvent(event: string) {
  const eventName = event + ' on page ' + location.origin + location.pathname;
  navigator.sendBeacon(
    publicRuntimeConfig.API_URL + '/_/tracking/track/',
    new URLSearchParams('event=' + eventName),
  );
}

export default trackEvent;
