import React from 'react';
import Layout from 'blocks/Layout';
import Onboarding from 'blocks/Onboarding';
import Feature from 'components/FeatureFlag';
import LanguagePicker from 'components/LanguagePicker';
import NODES from 'djedi-nodes/home';
import { ToggleableFeature } from 'utils/FeatureFlag/constants';

import styles from './FullscreenLogin.module.css';

const FullscreenLogin: React.FC = () => {
  return (
    <Layout
      title={NODES.META_TITLE}
      description={NODES.META_DESCRIPTION}
      // Hide hamburger menu during signup
      NavProps={{ color: 'white', responsiveColor: 'white', displayHamburgerMenu: false }}
      navShadow={true}
    >
      <Onboarding modal={false} />

      {/* Action buttons will be added here via a portal in '{...}/ContentWrapper/index.tsx' */}
      <div id="action-root" className={styles.actions} />

      <div className={styles.languagePicker}>
        <div className={styles.languagePickerInner}>
          <Feature id={ToggleableFeature.LANGUAGE_PICKER}>
            <LanguagePicker boxStyle />
          </Feature>
        </div>
      </div>
    </Layout>
  );
};

export default FullscreenLogin;
