import React, { useEffect } from 'react';
import trackEvent from 'api/ous_tracking';
import Layout from 'blocks/Layout';
import Block from 'components/Block';
import Button from 'components/Button';
import Typography from 'components/Typography';
import NODES from 'djedi-nodes/study';
import { ForceNodes } from 'djedi-react';

import styles from './OUSwelcome.module.css';

const OUSintro: React.FC = () => {
  // Track OUS index page view
  useEffect(() => {
    if (typeof window !== 'undefined') {
      trackEvent('Visit');
    }
  }, []);
  return (
    <Layout
      title={NODES.META_TITLE}
      description={NODES.META_DESCRIPTION}
      NavProps={{ color: 'white', responsiveColor: 'white', displayHamburgerMenu: false }}
    >
      <div className={styles.root}>
        <div className={styles.infoBox}>
          <Typography variant="largetitle" align="center" component="h1">
            {NODES.INTRO_TITLE}
          </Typography>
          <Typography align="center" block>
            {NODES.INTRO_BODY}
          </Typography>
        </div>

        <Block className={styles.CTABox}>
          <Button href="/study" onClick={() => trackEvent('Click accept')} block>
            {NODES.INTRO_ACCEPT_BUTTON_TEXT}
          </Button>
          <Button
            href="https://www.oslo-universitetssykehus.no/behandlinger/fosterdiagnostikk"
            block
            onClick={() => trackEvent('Click decline')}
          >
            {NODES.INTRO_DECLINE_BUTTON_TEXT}
          </Button>
        </Block>
      </div>
      <ForceNodes>{NODES}</ForceNodes>
    </Layout>
  );
};

export default OUSintro;
