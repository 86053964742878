import React from 'react';
import cx from 'classnames';

import PlaySVG from '../../icons/play_circle_outline.svg';
import styles from './EmbeddedVideo.module.css';

export interface EmbeddedVideoProps {
  coverImage: string | React.ReactElement;
  videoEmbedUrl: string;
  className?: string;
}

const EmbeddedVideo: React.FC<EmbeddedVideoProps> = ({ coverImage, videoEmbedUrl, className }) => {
  const [playVideo, setPlayVideo] = React.useState(false);
  return (
    <>
      {!playVideo && (
        <div className={cx(styles.coverContainer, className)}>
          {typeof coverImage === 'string' ? (
            <img src={coverImage} alt={videoEmbedUrl} />
          ) : (
            coverImage
          )}
          <button className={styles.play} onClick={() => setPlayVideo(true)}>
            <PlaySVG />
          </button>
        </div>
      )}
      {playVideo && (
        <div className={cx(styles.videoContainer, className)}>
          <iframe
            src={videoEmbedUrl}
            className={styles.iframe}
            frameBorder="0"
            allow="autoplay; fullscreen; picture-in-picture"
            allowFullScreen
          />
        </div>
      )}
    </>
  );
};

export default EmbeddedVideo;
