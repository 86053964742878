import React, { ReactNode } from 'react';
import cx from 'classnames';

import Typography from '../../components/Typography';
import styles from './ActionCard.module.css';

interface Props {
  className?: string;
  title: string | ReactNode;
  icon?: React.FC<React.SVGAttributes<React.ReactNode>>;
}

const ActionCard: React.FC<Props> = ({ children, title, icon: Icon, className, ...rest }) => (
  <div className={cx(styles.root, className)} {...rest}>
    <header className={styles.header}>
      <Typography variant="heading" component="h3" raw>
        {title}
      </Typography>
      {Icon && <Icon height={32} width={32} />}
    </header>
    <div className={styles.inner}>{children}</div>
  </div>
);

export default ActionCard;
