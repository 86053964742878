import React, { ReactElement } from 'react';

import { DjediNodeState } from '../../../types/DjediNode';

type Props = {
  node: ReactElement<{
    render: (state: DjediNodeState) => void;
    state: never;
  }>;
  render: (v: DjediNodeState) => ReactElement;
};

const DjediContent: React.FC<Props> = ({ node, render }) => {
  return React.cloneElement(node, {
    render: (state: DjediNodeState) => (state.type === 'success' ? render(state) : null),
  });
};

export default DjediContent;
