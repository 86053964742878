import React from 'react';
import cx from 'classnames';

import WithColor from '../../hocs/WithColor';
import SwooshSVG from '../../icons/swoosh.svg';
import Block, { BlockProps } from '../Block';
import styles from './SwooshBlock.module.css';

type yPos = 'top' | 'center' | 'bottom';

type Props = {
  from?: Color;
  to?: Color;
  BlockProps?: BlockProps;
  className?: string;
  noScroll?: boolean;
};

/**
 helper component
 - Receives the `color` prop via WithColor as className
*/

type BgProps = {
  position?: yPos;
  className?: string;
  mirrorX?: boolean;
  mirrorY?: boolean;
};

const Bg = WithColor<BgProps>(
  ({ position = 'top', mirrorX = false, mirrorY = false, className }) => (
    <div
      aria-hidden={true}
      className={cx(
        styles.background,
        {
          [styles.bottom]: position === 'bottom',
          [styles.center]: position === 'center',
          [styles.useInherit]: mirrorY,
        },
        className,
      )}
    >
      <SwooshSVG
        alt=""
        fill="currentColor"
        className={cx(styles.swoosh, { [styles.mirrorX]: mirrorX, [styles.mirrorY]: mirrorY })}
      />
    </div>
  ),
  { color: 'white' },
);

/**
 * Intended to be used as a <Block/> passing content into it and allowing the swoosh
 * to transition between two colors in the background.
 *
 * @param position the swoosh can be positioned at the bottom, center or top of the block. Might be hard to differ in low-height content blocks.
 * @param from color to transit from, can't be transparent since it works by overlapping the background.
 * @param to color to transit to, can't be transparent if mirroY=true
 * @param mirrorX mirror the swoosh shape along the X-axis (from/to are left intact)
 * @param mirrorY mirror the swoosh shape along the Y-axis (from/to are left intact)
 * @param BlockProps props passed directly to the Block element
 */

const SwooshBlock: React.FC<Props & BgProps> = ({
  children,
  from = 'white',
  to = 'pink',
  noScroll = true,
  BlockProps,
  className,
  ...bgProps
}) => {
  const { mirrorY } = bgProps;
  return (
    <Block background={to} {...BlockProps} className={cx({ [styles.root]: noScroll }, className)}>
      <Bg color={mirrorY ? to : from} background={mirrorY ? from : undefined} {...bgProps} />
      <div className={styles.content}>{children}</div>
    </Block>
  );
};

export default SwooshBlock;
