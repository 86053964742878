import React from 'react';
import { Node } from 'djedi-react';

const pageName = 'study';

const NODES = {
  META_TITLE: <Node uri={`${pageName}/meta/title`}>Välkommen till studien</Node>,
  META_DESCRIPTION: (
    <Node uri={`${pageName}/meta/description`}>
      Din medbverkan är helt anonym och frivillig. Följ stegen nedan för att komma igång.
    </Node>
  ),
  INTRO_TITLE: (
    <Node uri={`${pageName}/intro/title`}>
      Velkommen till informasjon om tidlig ultralyd pg NIPT ved OUS
    </Node>
  ),
  INTRO_BODY: (
    <Node uri={`${pageName}/intro/body`}>
      Vi ønsker å forbedre informasjonen til deg/dere som tilbys fosterdiagnostikk. Derfor
      gjennomfører vi en studie. Studiedeltagelse innebærer at du svarer anonynmt på et digitalt
      spørreskjema etter at du har gått igjennom informasjonsreisen.
    </Node>
  ),
  INTRO_ACCEPT_BUTTON_TEXT: <Node uri={`${pageName}/intro/accept-button-text`}>Jeg forstår</Node>,
  INTRO_DECLINE_BUTTON_TEXT: (
    <Node uri={`${pageName}/intro/decline-button-text`}>Jeg ønsker ikke å delta</Node>
  ),
  WELCOME_TITLE: <Node uri={`${pageName}/welcome/title`}>Välkommen till studien</Node>,
  WELCOME_BODY: (
    <Node uri={`${pageName}/welcome/body`}>
      Din medbverkan är helt anonym och frivillig. Följ stegen nedan för att komma igång.
    </Node>
  ),
  WELCOME_LINK: <Node uri={`${pageName}/welcome/link`}>Vill du veta mer om studien?</Node>,
  ENTRY_PHONE: <Node uri={`${pageName}/entry/phonenumber`}>Telefonnummer</Node>,
  ENTRY_PHONE_USAGE: (
    <Node uri={`${pageName}/entry/phonenumber/usage`}>Hva brukes telefonnummeret mit til?</Node>
  ),
  ENTRY_AGE: <Node uri={`${pageName}/entry/age`}>Din alder</Node>,
  ENTRY_AGE_YOUNG: <Node uri={`${pageName}/entry/age/young`}>Under 35 år</Node>,
  ENTRY_AGE_OLD: <Node uri={`${pageName}/entry/age/old`}>Over 35 år</Node>,
  SUBMIT_BUTTON: <Node uri={`${pageName}/entry/submit`}>Gå vidare</Node>,
  REDIRECT_NOTICE: (
    <Node uri={`${pageName}/entry/redirect-notice`}>Du skickas strax vidare...</Node>
  ),
  GO_TO_SURVEY_TITLE: (
    <Node uri={`${pageName}/go-to-survey/title`}>Vi har noen spørsmål til deg</Node>
  ),
  GO_TO_SURVEY_BODY: (
    <Node uri={`${pageName}/go-to-survey/body`}>
      Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc posuere dui sem, semper sodales
      libero luctus sit amet. Morbi fermentum rutrum cursus.
    </Node>
  ),
  GO_TO_SURVEY_BUTTON: <Node uri={`${pageName}/go-to-survey/button`}>Fyll ut spørreskjemaet</Node>,
};

export default NODES;
