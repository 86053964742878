import { useCallback, useRef, useState } from 'react';

const DEFAULT_ROOT = null;
const DEFAULT_ROOT_MARGIN = '0px';
const DEFAULT_THRESHOLD = [0];

type SetObserverType = (node: Element | null) => void;

type IntersectionObserverReturnType = [
  SetObserverType,
  IntersectionObserverEntry | null,
  IntersectionObserver | null,
];

/**
 * IntersectionObserver hook
 *
 * - Call the hook with any IntersectionObserver standard parameters:
 *     const [observe, entry, _observer] = useIntersectionObserver({_settings_})
 * - Deconstruct the returned Tuple:
 * -- The first item, the "observe" is a function that takes a ref to the element
 *    that's going to be checked for intersections
 * -- The second item is the entry. This is what's updated then the status of the
 *    intersection changes.
 * -- The third one is the IntersectionObserver itself. This can be used to disconnect
 *    change the observer directly.
 *
 */

const useIntersectionObserver = ({
  root = DEFAULT_ROOT,
  rootMargin = DEFAULT_ROOT_MARGIN,
  threshold = DEFAULT_THRESHOLD,
  ...settings
}: IntersectionObserverInit = {}): IntersectionObserverReturnType => {
  const observerRef = useRef<IntersectionObserver | null>(null);
  const [entry, setEntry] = useState<IntersectionObserverEntry | null>(null);

  const observe = useCallback(
    (node: Element | null) => {
      // (node: Element | null) => void
      function getObserver() {
        // If there is no observer, then create it.

        if (!observerRef.current) {
          observerRef.current = new IntersectionObserver(
            ([entry]) => {
              setEntry(entry);
            },
            { root, rootMargin, threshold, ...settings },
          );
        }

        return observerRef.current;
      }

      const observer = getObserver();
      observer.disconnect();

      if (node) {
        observer.observe(node);
      }
    },
    [root, settings, rootMargin, threshold],
  );

  return [observe, entry, observerRef.current];
};

export default useIntersectionObserver;
