import Layout from 'blocks/Layout';
import cx from 'classnames';
import Block from 'components/Block';
import Button from 'components/Button';
import Container from 'components/Container';
import DjediContent from 'components/DjediContent';
import Spacing from 'components/Spacing';
import SwooshBlock from 'components/SwooshBlock';
import NODES, { STEPS } from 'djedi-nodes/home';
import TIMESLOTS_NODES from 'djedi-nodes/timeslots';
import { ForceNodes } from 'djedi-react';
import Faq from 'eddalabs/components/Faq';
import Typography from 'eddalabs/components/Typography';
import { NextPage } from 'next';
import SearchTimeslotsForm from 'partials/Timeslots/SearchTimeslotsForm/SearchTimeslotsForm';
import { DataResult } from 'utils/data-sources';

import { Service } from '../../../types/Clinic';
import { FaqEntry } from '../../../types/Lesson';
import { City } from '../../../types/Timeslots';
import TrustpilotWidget from '../../components/TrustpilotWidget';
import RocketLaunchSVG from '../../icons/rocket_launch.svg';
import RunningTimeSVG from '../../icons/running_time.svg';
import styles from './BookingIntro.module.css';

const BookingIntro: NextPage<{
  questions: FaqEntry[];
  cities: DataResult<City[]>;
  services: DataResult<Service[]>;
}> = ({ questions, cities, services }) => {
  const intro = (
    <div className={styles.introContainer}>
      <TrustpilotWidget />
      <Block padding="none" type="flex" className={cx(styles.heroContainer, styles.heroMaxWidth)}>
        <Typography component="h1" variant="hero">
          {NODES.INTRO_TITLE}
        </Typography>
        <RunningTimeSVG height={70} width={70} fill="#999" />
      </Block>
      <Spacing />
      <SearchTimeslotsForm cities={cities} services={services} />
    </div>
  );

  const contrastColor = 'white';

  return (
    <Layout
      title={NODES.META_TITLE}
      description={NODES.META_DESCRIPTION}
      NavProps={{ color: 'white', responsiveColor: contrastColor }}
    >
      <div className={styles.maxWidthWrapper}>
        <Spacing />
        <div className={styles.desktopOnly}>
          <SwooshBlock
            mirrorY
            position="bottom"
            to={contrastColor}
            className={cx(
              // SearchTimeslotsForm date picker overflows vertically
              styles.overflowYSwooshBlock,
            )}
          >
            <div className={styles.desktopIntro}>{intro}</div>
          </SwooshBlock>
        </div>
        <div className={styles.phoneOnly}>
          <SwooshBlock
            className={styles.swooshMobileBlock}
            from="white"
            to={contrastColor}
            mirrorY
            position="bottom"
          >
            <Block>
              <Container className={cx(styles.intro, styles.mobile)}>{intro}</Container>
            </Block>
          </SwooshBlock>
        </div>
        <Spacing size={6} />
        <Block background={contrastColor} padding="bottom-extra" component="article">
          <Container className={styles.bookingIntroContainer}>
            <div className={styles.bookingIntroBackground}>
              {NODES.BOOKING_INTRO_BACKGROUND}
              <Typography variant="heading" component="h2" color="white" className={styles.title}>
                {NODES.BOOKING_INTRO_SHORT_TITLE}
              </Typography>
              <div className={styles.buttonWrapper}>
                <DjediContent
                  node={NODES.BOOKING_INTRO_CTA_LINK}
                  render={s => (
                    <Button
                      className={cx(styles.button)}
                      background="white"
                      href={s.content.props.children}
                    >
                      {NODES.BOOKING_INTRO_CTA_TEXT}
                    </Button>
                  )}
                />
              </div>
            </div>
          </Container>
        </Block>
        <Block padding="none" className={styles.introContainer}>
          <Container>
            <div className={styles.iconFlexContainer}>
              <Typography variant="heading" component="h2" className={styles.bookingIntroTitle}>
                {NODES.BOOKING_INTRO_TITLE}
              </Typography>
              <RocketLaunchSVG className={styles.bookingIntroIcon} />
            </div>
            <Typography className={styles.bookingIntroDescription}>
              {NODES.BOOKING_INTRO_DESCRIPTION}
            </Typography>
          </Container>
        </Block>
        <Spacing size={1} />
        {Object.keys(NODES.SERVICES).map((service, i) => (
          <Block padding="none" key={i} className={styles.servicesBlock}>
            <Container>
              <div key={i} className={styles.servicesIconPart}>
                <div className={styles.servicesIcon}>{NODES.SERVICES[service].ICON}</div>
                <Typography block key={i} variant="heading" className={styles.clinicTitle}>
                  {NODES.SERVICES[service].NAME}
                </Typography>
              </div>
              <Typography block key={i} className={styles.clinicDescription}>
                {NODES.SERVICES[service].SUMMARY}
              </Typography>
            </Container>
          </Block>
        ))}
        <Spacing size={6} />
        <Faq questions={questions} />
        <Spacing size={8} />
        <ForceNodes>
          {NODES}
          {STEPS}
          {/** Used in `SearchTimeslotsForm`. */}
          {/** (Maybe make separation djedi-nodes/search-timeslots and djedi-nodes/timeslots?) */}
          {TIMESLOTS_NODES}
        </ForceNodes>
      </div>
    </Layout>
  );
};

export default BookingIntro;
