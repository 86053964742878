import React from 'react';
import Container from 'components/Container';
import Expandable from 'components/Expandable';
import Spacing from 'components/Spacing';
import NODES from 'djedi-nodes/home';
import Typography from 'eddalabs/components/Typography';

import { FaqEntry } from '../../../../types/Lesson';
import Block from '../../../components/Block';
import styles from './Faq.module.css';

const Faq: React.FC<{ questions: FaqEntry[]; title?: string }> = ({ questions = [], title }) => {
  return (
    <>
      <Block padding="none">
        <Container>
          {title ? (
            <Typography variant="heading" component="h2" className={styles.title}>
              {title}
            </Typography>
          ) : (
            <>
              <Typography variant="heading" component="h2" className={styles.title}>
                {NODES.FAQ_TITLE}
              </Typography>
              <Typography variant="body" className={styles.subtitle}>
                {NODES.FAQ_SUBTITLE}
              </Typography>
            </>
          )}
          <Spacing size={1} />
          {questions.map(({ question, answer }, i) => (
            <Expandable
              key={i}
              title={<Typography className={styles.questionText}>{question}</Typography>}
              className={styles.expandable}
              expandableClassName={styles.innerExpandable}
              noButtonPadding
            >
              <Typography className={styles.answerText}>{answer}</Typography>
            </Expandable>
          ))}
        </Container>
      </Block>
    </>
  );
};

export default Faq;
