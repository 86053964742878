import React from 'react';
import cx from 'classnames';

import WithColor from '../../hocs/WithColor';
import useIntersectionObserver from '../../hooks/useIntersectionObserver';
import { clamp } from '../../utils';
import styles from './CircularProgress.module.css';

const VIEWPORT_BASE = 100;
const R = VIEWPORT_BASE * 0.5;
const DASH_ARRAY = Math.PI * (R * 2);
const MAX_ANIMATION_TIME = 1.5;

type Props = {
  total?: number;
  progress: number;
  children?: number;
  strokeWidth?: number;
  className?: string;
  color?: string;
};

/**
 * Circular Progress bar that fires the animation when fully visible in the viewport
 *
 * @param strokeWidth Width of the SVG stroke
 * @param color Color of the SVG stroke
 * @param total Number that corresponds to 100% of the progress.
 * @param progress Number in the range of [0 - $total]
 * @param children Number to be displayed inside Element
 * @returns A functional component
 */

const CircularProgress: React.FC<Props> = ({
  total = 1,
  className,
  strokeWidth = 10,
  progress,
  children,
}) => {
  /* 
    The R(adius) value is the center of circles size. the real viewport
    is calculated prevent the strokes being clipped
  */
  const calculatedR = (VIEWPORT_BASE + strokeWidth) / 2;
  const viewboxTotal = VIEWPORT_BASE + strokeWidth * 2;
  const viewbox = [-strokeWidth, -strokeWidth, viewboxTotal, viewboxTotal].join(' ');

  // hooks
  const [progressFactor, setProgressFactor] = React.useState<number>(total);
  const [setObserver, entry, observer] = useIntersectionObserver({ threshold: 1 });
  const ref = React.useRef<HTMLDivElement>(null);

  // Use an IntersectionObserver to start the animation when the user can see it.
  React.useEffect(() => {
    if (setObserver) {
      setObserver(ref.current);
    }
  }, [setObserver]);

  React.useEffect(() => {
    if (entry?.isIntersecting && observer) {
      setProgressFactor(clamp(total - progress, 0, total) / total);

      // disconnect the observer when it's not needed anymore
      observer.disconnect();
    }
    return () => observer?.disconnect();
  }, [entry, setProgressFactor, observer, progress, total]);

  return (
    <div className={cx(styles.root, className)} aria-hidden="true" ref={ref}>
      <svg
        strokeDasharray={DASH_ARRAY}
        strokeDashoffset={progressFactor * DASH_ARRAY}
        strokeWidth={strokeWidth}
        viewBox={viewbox}
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle
          cx={calculatedR}
          cy={calculatedR}
          r={R}
          fill="none"
          stroke="currentColor"
          strokeLinecap="round"
          style={{
            transition: `stroke-dashoffset ${MAX_ANIMATION_TIME * (1 - progressFactor)}s ease-out`,
          }}
        />
        <text
          x={calculatedR}
          y={calculatedR + 4}
          dominantBaseline="middle"
          textAnchor="middle"
          className={styles.text}
        >
          {children}
        </text>
      </svg>
    </div>
  );
};

export default WithColor(CircularProgress, { color: 'green' });
